<template>
    <CRow style="margin-top: 20px">
        <CCol sm="12">
            <CTabs variant="tabs" :active-tab="tabChange" @update:activeTab="handleTab">
                <CTab :title="$t('label.filesTransferred')" class="form-especial">
                    <transferir-dispositivo :valor="transferir" @child-refresh="transferir=false" />
                </CTab>
                <CTab :title="$t('label.transferredFiles')" class="form-especial">
                    <ArchivosTransferidos :value="tabChange" />
                </CTab>
            </CTabs>
        </CCol>
    </CRow>
</template>

<script>
    import { mapState } from "vuex";
    import TransferirDispositivo from './transferir-dispositivo';
    import ArchivosTransferidos from './archivos-transferidos';
    //data
    function data() {
        return {
            tabChange: 0,
        }
    }
    //methods
    function handleTab(tab) {
        this.tabChange = tab;
    }
    //computed
    function transferir(){
        return this.tabChange === 0;
    }
    export default {
        name:'transferencia-archivos',
        data,
        methods:{
            handleTab
        },
        computed:{
            transferir,
            ...mapState({
                tabIndex: (state) => state.planificacionestiba.tabIndex,
            })
        },
        components:{
            TransferirDispositivo,
            ArchivosTransferidos
        },
        watch:{
            tabIndex(newValue,OldValue){
                if(OldValue === 7){
                    this.tabChange = 0;
                }
            }
        }
    }
</script>