<template>
    <div class="container-fluid mb-4">
        <CRow>
            <CCol sm="12" v-if="collapseItems">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    :loading="apiStateLoading"
                    column-filter
                    :table-filter="tableTextHelpers.tableFilterText"
                    :items-per-page-select="tableTextHelpers.itemsPerPageText"
                    :items="dateFilteredItems"
                    :fields="fields"
                    :noItemsView="tableTextHelpers.noItemsViewText"
                    :items-per-page="5"
                    pagination
                >
                    <template #Nro="{index}">
                        <td class="center-cell text-center">
                            {{index+1}}
                        </td>
                    </template>
                    <template #textStatus="{ item }">
                        <td class="text-center">
                            <CBadge :color="getBadge(item.textStatus)">
                                {{ $t(item.textStatus)}}
                            </CBadge>
                        </td>
                    </template>
                    <template #Action="{item}">
                        <td>
                            <CButton
                                square
                                color="watch"
                                size="sm"
                                v-c-tooltip="{content: $t('label.confirmedCOntainersDetails') ,
                                    placement: 'top-end'}"
                                @click="updateCollapse(item)"
                            >
                                <CIcon name="cil-list"/>
                            </CButton>
                            <CButton
                                square
                                color="revert"
                                size="sm"
                                class="ml-1"
                                v-c-tooltip="{
                                    content: $t('label.reverse'),
                                    placement: 'top-end'
                                }"
                                v-if="item.FgActTransfer"
                                @click="updateConfirmed(item)"
                            >
                                <CIcon name="cil-reload"/>
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
            <CCol sm="12" v-else>
                <CRow>
                    <CCol sm="12">
                        <CRow style="margin-bottom:10px;margin-top:10px;">
                            <CCol sm="12" lg="3" class="d-flex align-items-center">
                                <CButton
                                    color="edit"
                                    class="mr-2"
                                    v-c-tooltip="{
                                        content: $t('label.return'),
                                        placement: 'top',
                                    }"
                                    size="sm"
                                    @click="desactivar()"
                                    :disabled="apiStateLoading"
                                >
                                    <CIcon name="cil-chevron-left-alt" />
                                </CButton>
                            </CCol>
                            <CCol sm="12" lg="6" class="text-center">
                                <h5 class="mt-2"> {{ $t('label.confirmedContainers') }}</h5>
                            </CCol>
                            <CCol sm="12" lg="3" class="d-flex align-items-center justify-content-end">
                                <CButton
                                    color="excel"
                                    @click="onBtnExport(true)"
                                >
                                    <CIcon name="cil-file-excel"/>&nbsp; XLSX
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCol>
                    <CCol sm="12">
                        <dataTableExtended
                            class="align-center-row-datatable"
                            hover
                            sorter
                            small
                            column-filter
                            :table-filter="tableTextHelpers.tableFilterText"
                            :items-per-page-select="tableTextHelpers.itemsPerPageText"
                            :items="dateFilteredItemsSmall"
                            :fields="fieldsSmall"
                            :noItemsView="tableTextHelpers.noItemsViewText"
                            :items-per-page="5"
                            pagination
                            @filtered-items-change="getFilteredList"
                        >
                            <template #RowNumber="{index}">
                                <td class="center-cell text-center">
                                    {{index+1}}
                                </td>
                            </template>
                        </dataTableExtended>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import Reportes from '@/_mixins/reportes';
    import ModalMixin from '@/_mixins/modal';
    function data() {
        return {
            listItems : [],
            listItemsSmall:[],
            EditName: "",
            collapseItems: true,
            filteredList: [],
        }
    }
    function updateCollapse(item){
        if(item.ConfirmedJson !== null && item.ConfirmedJson !== undefined){
            this.listItemsSmall = item.ConfirmedJson;
            this.EditName = item.EdiFileName;
            this.collapseItems = false;
        }
    }
    function updateConfirmed (item){
        this.$swal
            .fire(this.alertProperties({
                text: `${this.$t('label.reverseFile')}`,
            }))
            .then((result) => {
            if (result.isConfirmed) {
                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                let valores = {
                    TransferId:  item.TransferId,
                }
                this.$http.ejecutar('POST', 'DeviceTransferenceReverse',valores, { root: 'TransferJson' })
                .then(
                responseAll => {
                    if(responseAll.status === 200){
                        let response = responseAll.data.data;
                        item.ValueDsEs = response[0].ValueDsEs;
                        item.ValueDsEn = response[0].ValueDsEn;
                        item.FgActTransfer = false;
                        item.Status = response[0].Status;
                        item.ConfirmedJson = [];
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADED;
                    }else{  
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    }
                },error => {
                    this.$store.commit('planificacionestiba/messageMutation', error);
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                });
            }else {
                // this.$swal(this.$t('label.operationCancelled'));
            }
        })
    }
    function desactivar(){
        this.listItemsSmall = [];
        this.EditName = "";
        this.collapseItems = true;
    }
    async function onBtnExport(valor){
        if(this.dateFilteredItems.length !== 0 ){
            if(this.filteredList.length !== 0){
                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                let TransferFileExcel = [];
                this.filteredList.forEach((item) => {
                    let arreglo = {};
                    arreglo['#']=item.Nro ? item.Nro : "";
                    arreglo[this.$t('label.containerCode')]=item.ContainerCode ? item.ContainerCode : "";
                    arreglo[this.$t('label.size')+' (FT)']=item.TpCargoCode ? item.TpCargoCode : 0;
                    arreglo[this.$t('label.isoCode')]=item.IsoCode ? item.IsoCode : "";
                    arreglo[this.$t('label.Seal')+'S']=item.Seals ? item.Seals : "";
                    arreglo[this.$t('label.status')]=item.Status ? item.Status : "";
                    arreglo[this.$t('label.carrier')]=item.ShippingLineCode ? item.ShippingLineCode : "";
                    arreglo['IMDG']=item.ImdgCode ? item.ImdgCode : "";
                    arreglo['POL']=item.LoadPort ? item.LoadPort : "";
                    arreglo['POD']=item.DischargePort ? item.DischargePort : "";
                    arreglo['PODEL']=item.DeliveryPort ? item.DeliveryPort : "";
                    arreglo['POT']=item.TranshipmentPort ? item.TranshipmentPort : "";
                    arreglo[this.$t('label.weight')+' (KGM)']=item.Weigth ? item.Weigth : 0;
                    arreglo['VGM (KGM)']=item.Vgm ? item.Vgm : 0;
                    arreglo[this.$t('label.certificateVGM')]=item.VgmCertificate ? item.VgmCertificate : "";
                    arreglo[this.$t('label.BaysLot')]=item.BaySlot ? item.BaySlot : "";
                    arreglo[this.$t('label.crane')]=item.CraneName ? item.CraneName : "";
                    arreglo[this.$t('label.stowageMovement')]=item.StowageEventName ? item.StowageEventName : "";
                    arreglo[this.$t('label.confirmationDate')]=item.fecharegistrotransa ? item.fecharegistrotransa : "N/A";
                    arreglo[this.$t('label.device')]=item.DeviceName ? item.DeviceName : "";
                    TransferFileExcel.push(arreglo);
                });
                await this.getExcelArray(TransferFileExcel,this.EditName,valor);
                this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
            }else{
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailbleReport'),
                    type: "error"
                });
            }
        }
    }
    //computed
    function dateFilteredItems(){
        let label = this.$i18n.locale;
        if(this.value == 1){
            this.listItems.map(async(item) => {
                item.textStatus = label == "es" ? item.ValueDsEs : item.ValueDsEn;
                item.fecharegistrotransa = item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A';
            })
            return this.listItems;
        }
        return [];
    }
    function dateFilteredItemsSmall(){
        let label = this.$i18n.locale;
        if(!this.collapseItems){
            this.listItemsSmall.map(async(item,index) => {
                item.Nro= item.Nro;
                item.ContainerCode= item.ContainerCode ? item.ContainerCode : "";
                item.Size= item.Size ? item.Size : 0;
                item.Status= item.Status ? item.Status : "";
                item.ShippingLineCode= item.ShippingLineCode ? item.ShippingLineCode : "";
                item.IsoCode= item.IsoCode ? item.IsoCode : "";
                item.ImdgCode= item.ImdgCode ? item.ImdgCode : "";
                item.LoadPort= item.LoadPort ? item.LoadPort : "";
                item.DischargePort= item.DischargePort ? item.DischargePort : "";
                item.DeliveryPort= item.DeliveryPort ? item.DeliveryPort : "";
                item.TranshipmentPort= item.TranshipmentPort ? item.TranshipmentPort : "";
                item.Seals= item.Seals ? item.Seals : "";
                item.Weigth= item.Weigth ? item.Weigth : 0;
                item.Vgm= item.Vgm ? item.Vgm : 0;
                item.VgmCertificate= item.VgmCertificate ? item.VgmCertificate : "";
                item.BaySlot= item.BaySlot ? item.BaySlot : "";
                item.DeviceName= item.DeviceName ? item.DeviceName : "";
                item.CraneName= item.CraneName ? item.CraneName : "";
                item.StowageEventName = label == "es" ? item.StowageEventNameEs : item.StowageEventNameEn;
                item.fecharegistrotransa = item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A';
            });
            return this.listItemsSmall;
        }
        return [];
    }
    function fields(){
        return [
            { key: 'Nro', label: '#',filter :false, _style:'width:1%; text-align: center;' },
            { key: 'EdiFileName', label: this.$t('label.file'),_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'Confirmed', label: this.$t('label.confirmedContainers'),_classes:"center-cell"},
            { key: 'PendingConfirmation', label: this.$t('label.tobeconfirmed'),_classes:"center-cell"},
            { key: 'Planning', label: this.$t('label.totalContainers'),_classes:"center-cell"},
            { key: 'fecharegistrotransa', label:this.$t('label.date'), _classes:'center-cell',_style:'width:10%;'},
            { key: 'TransaLogin', label: this.$t('label.user'),_classes:"center-cell" },
            { key: 'textStatus', label: this.$t('label.status'),_classes:'center-cell',_style:'width:5%;' },
            { 
                key: 'Action',
                _style:'width:1%;min-width:90px;',
                _classes:"center-cell",
                label: '', 
                sorter: false, 
                filter: false
            }
        ]
    }
    function fieldsSmall(){
        return [
            { key: 'Nro', label: '#',filter :false, _style:'width:1%; text-align: center;' },
            { key: 'ContainerCode', label: this.$t('label.containerCode'),_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'TpCargoCode', label: this.$t('label.size')+' (FT)',_classes:"center-cell"},
            { key: 'IsoCode', label: this.$t('label.isoCode'),_classes:"center-cell"},
            { key: 'Seals', label: this.$t('label.Seal')+'s',_classes:"center-cell"},
            { key: 'Status', label: this.$t('label.status'),_classes:"center-cell"},
            { key: 'ShippingLineCode', label: this.$t('label.carrier'),_classes:"center-cell"},
            { key: 'ImdgCode', label: 'IMDG',_classes:"center-cell"},
            { key: 'LoadPort', label: 'POL',_classes:"center-cell", _style:'min-width:100px;'},
            { key: 'DischargePort', label: 'POD',_classes:"center-cell", _style:'min-width:100px;'},
            { key: 'DeliveryPort', label: 'PODEL',_classes:"center-cell", _style:'min-width:100px;'},
            { key: 'TranshipmentPort', label: 'POT',_classes:"center-cell", _style:'min-width:100px;'},
            { key: 'Weigth', label: this.$t('label.weight') + ' (KGM)',_classes:"center-cell"},
            { key: 'Vgm', label: 'VGM' + ' (KGM)',_classes:"center-cell"},
            { key: 'VgmCertificate', label: this.$t('label.certificateVGM'),_classes:"center-cell text-left", _style:'min-width:150px;text-align: left;'},
            { key: 'BaySlot', label: "BAYSLOT",_classes:"center-cell"},
            { key: 'CraneName', label: this.$t('label.crane'),_classes:"center-cell"},
            { key: 'StowageEventName', label: this.$t('label.stowageMovement'),_classes:"center-cell"},
            { key: 'fecharegistrotransa', label:this.$t('label.confirmationDate'), _classes:'center-cell',_style:'width:10%;'},
            { key: 'DeviceName', label: this.$t('label.device'),_classes:"center-cell"},
        ]
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function value(newValue){ 
        if(newValue == 1){
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
            let valores = {
                StowagePlanningId:  this.StowagePlanning,
                CompanyBranchId:  this.branchSelected.CompanyBranchId,
            }
            this.$http.ejecutar('POST', 'StowagePlanningEdiFileTransfer-list',valores, { root: 'StowagePlanningFileJson' })
            .then(
            responseAll => {
                if(responseAll.status === 200){
                    let response = responseAll.data.data;
                    let keys = Object.keys(response[0]);
                    if(keys[0] === 'Json'){
                        this.listItems = []
                    }else{
                        this.listItems = response;
                    }
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                }else{  
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                }
            },error => {
                this.$store.commit('planificacionestiba/messageMutation', error);
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            });
        }else{
            this.listItems = [];
            this.collapseItems = true;
        }
    }

    function getFilteredList(arr) {
        this.filteredList = arr;
    }

    function getBadge(status) {
        switch (status) {
            case 'INACTIVO':
                return 'danger';
            case 'INACTIVE':
                return 'danger';
        }
    }

    export default {
        name:'archivos-transferidos',
        props:['value'],
        data,
        methods:{
            updateCollapse,
            desactivar,
            onBtnExport,
            updateConfirmed,
            getFilteredList,
            getBadge
        },
        mixins: [Reportes,ModalMixin],
        mounted(){
            if(this.value == 1){
                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                let valores = {
                    StowagePlanningId:  this.StowagePlanning,
                    CompanyBranchId:  this.branchSelected.CompanyBranchId,
                }
                this.$http.ejecutar('POST', 'StowagePlanningEdiFileTransfer-list',valores, { root: 'StowagePlanningFileJson' })
                .then(
                responseAll => {
                    if(responseAll.status === 200){
                        let response = responseAll.data.data;
                        let keys = Object.keys(response[0]);
                        if(keys[0] === 'Json'){
                            this.listItems = []
                        }else{
                            this.listItems = response;
                        }
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    }else{  
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    }
                },error => {
                    this.$store.commit('planificacionestiba/messageMutation', error);
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                });
            }
        },
        beforeDestroy(){
            this.listItems = [];
            this.collapseItems = true;
        },
        computed:{
            fieldsSmall,
            tableTextHelpers,
            dateFilteredItems,
            dateFilteredItemsSmall,
            fields,
            apiStateLoading,
            ...mapState({
                user: state => state.auth.user,
                branchSelected: state => state.auth.branch,
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                EdiFileId: (state) => state.planificacionestiba.EdiFileId,
                EdiFileInfo: (state) => state.planificacionestiba.EdiFileInfo,
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
            })
        },
        watch:{
            value
        }
    }
</script>

<style lang="scss">
    .btn-revert {
        background-color: #EC631B;
        color: #fff;
    }
</style>