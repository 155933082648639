var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mb-4"},[_c('CRow',[(_vm.collapseItems)?_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","loading":_vm.apiStateLoading,"column-filter":"","table-filter":_vm.tableTextHelpers.tableFilterText,"items-per-page-select":_vm.tableTextHelpers.itemsPerPageText,"items":_vm.dateFilteredItems,"fields":_vm.fields,"noItemsView":_vm.tableTextHelpers.noItemsViewText,"items-per-page":5,"pagination":""},scopedSlots:_vm._u([{key:"Nro",fn:function({index}){return [_c('td',{staticClass:"center-cell text-center"},[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"textStatus",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.textStatus)}},[_vm._v(" "+_vm._s(_vm.$t(item.textStatus))+" ")])],1)]}},{key:"Action",fn:function({item}){return [_c('td',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$t('label.confirmedCOntainersDetails') ,
                                placement: 'top-end'}),expression:"{content: $t('label.confirmedCOntainersDetails') ,\n                                placement: 'top-end'}"}],attrs:{"square":"","color":"watch","size":"sm"},on:{"click":function($event){return _vm.updateCollapse(item)}}},[_c('CIcon',{attrs:{"name":"cil-list"}})],1),(item.FgActTransfer)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.reverse'),
                                placement: 'top-end'
                            }),expression:"{\n                                content: $t('label.reverse'),\n                                placement: 'top-end'\n                            }"}],staticClass:"ml-1",attrs:{"square":"","color":"revert","size":"sm"},on:{"click":function($event){return _vm.updateConfirmed(item)}}},[_c('CIcon',{attrs:{"name":"cil-reload"}})],1):_vm._e()],1)]}}],null,false,3920853228)})],1):_c('CCol',{attrs:{"sm":"12"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CRow',{staticStyle:{"margin-bottom":"10px","margin-top":"10px"}},[_c('CCol',{staticClass:"d-flex align-items-center",attrs:{"sm":"12","lg":"3"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                    content: _vm.$t('label.return'),
                                    placement: 'top',
                                }),expression:"{\n                                    content: $t('label.return'),\n                                    placement: 'top',\n                                }"}],staticClass:"mr-2",attrs:{"color":"edit","size":"sm","disabled":_vm.apiStateLoading},on:{"click":function($event){return _vm.desactivar()}}},[_c('CIcon',{attrs:{"name":"cil-chevron-left-alt"}})],1)],1),_c('CCol',{staticClass:"text-center",attrs:{"sm":"12","lg":"6"}},[_c('h5',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('label.confirmedContainers')))])]),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"3"}},[_c('CButton',{attrs:{"color":"excel"},on:{"click":function($event){return _vm.onBtnExport(true)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}}),_vm._v("  XLSX ")],1)],1)],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","column-filter":"","table-filter":_vm.tableTextHelpers.tableFilterText,"items-per-page-select":_vm.tableTextHelpers.itemsPerPageText,"items":_vm.dateFilteredItemsSmall,"fields":_vm.fieldsSmall,"noItemsView":_vm.tableTextHelpers.noItemsViewText,"items-per-page":5,"pagination":""},on:{"filtered-items-change":_vm.getFilteredList},scopedSlots:_vm._u([{key:"RowNumber",fn:function({index}){return [_c('td',{staticClass:"center-cell text-center"},[_vm._v(" "+_vm._s(index+1)+" ")])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }